import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb,Accordion } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Campusslidebaar from "../sidebars/CampusSidebar";
import feature from "../../Images/commonimg/Picture1.webp";
import benefit from "../../Images/commonimg/Capture.webp";
import EnquiryNow from "../common/EnquiryNow3";
 

const Digilearnsys = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
     
      <Topnav />
      <Menubar />
      <div className="dlsinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Digitized Learning System</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Digitized Learning System</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Campusslidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">Digitized Learning System</h2>
            <h4 className="mb-2 text-darkblue">
              ISBM University formally announces Digital Auditoriums on par with
              Digital India vision
            </h4>

            <p>
              ISBM University, awarded as "BEST UPCOMING UNIVERSITY" by the Hon.
              HRD Minister 'Shri Prakash Javdekar' in association with ASSOCHAM;
              has recently initiated Digital Auditoriums for digitizing lecture
              relays of the University. This concept of digitized lectures has
              been designed, equipped and digitized by ISBM University. The
              objective of the initiative is to provide a robust infrastructure
              platform to relay educational videos in addition to reaching
              far-flung and remotely located student community including all
              other learning stakeholders of the University through virtual
              technology in subjects like Science, Arts and Humanities, Commerce
              and Management, Library and Information Science, Journalism and
              Mass Communication among other faculties, from centrally located
              studios. The aim is to improve the teaching quality in colleges in
              remote areas. Being able to watch a lecture online means that
              students who can't be physically present in a lecture still feel
              part of a learning community and can see and hear their tutors
              speaking about a topic.
            </p>
            <Accordion  defaultActiveKey="0">
              <Accordion.Item eventKey="0" className="mt-3">
                <Accordion.Header > Features of ISBM University Digitized Learning Concept:</Accordion.Header>
                <Accordion.Body>
                <img src={feature}  className="mb-3 w-100" alt="Features of ISBM University"></img>
                  <p>
                    ISBM University is happy and proud in implementing this
                    project with assurance that this digital lectures concept
                    will be an asset for complete student-community. The idea is
                    to make eminent academicians and field experts available to
                    students with limited resources or from far-flung areas. The
                    university already has leading academicians and it also
                    intends to invite eminent personalities for lectures.
                    Lecturers from various different cities like Mumbai,
                    Hyderabad, Bangalore, Kolkata, Delhi, Chennai, etc. can
                    deliver lectures from their own cities without having to
                    travel to ISBM University's campus or elsewhere.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mt-3">
                <Accordion.Header>Benefits of ISBM University Digitized Learning Concept:</Accordion.Header>
                <Accordion.Body>
                <img src={benefit} className="mb-3 w-100" alt="Benefits of ISBM University"></img>
                  <p>
                    ISBM University has showed an exemplary support to Digital
                    India initiative and has kept up with their commitment to
                    support digitization. ISBM University is proud to align its
                    reform processes in tune with the national mission and
                    vision of Hon. Prime Minister of India under the banners of
                    Digital India, Make in India and Skills India. With this
                    initiative as a catalyst through developing a platform which
                    will boost educational content.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Digilearnsys;
