import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import about from "../../Images/commonimg/isbm12.webp";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow3";

import { MetaTags } from "react-meta-tags";
import AdmissionFormModal from "../common/AdmissionFormModal";

const Aboutus = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [modalShow1, setModalShow1] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>ISBM University Empowering Through Affordable Education</title>
        <meta
          name="description"
          content="ISBM University is one of the best university for affordable academic excellence for diploma & masters programs empowering students through quality education."
        />
        <meta
          name="keywords"
          content="ISBM University Chhattisgarh, Best university in chhattisgarh, ISBM university raipur"
        />

        <link rel="canonical" href="https://isbmuniversity.edu.in/about-us" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="ISBM University Empowering Through Affordable Education"
        />
        <meta
          property="og:description"
          content="ISBM University is one of the best university for affordable academic excellence for diploma & masters programs empowering students through quality education."
        />
        <meta
          property="og:url"
          content="https://isbmuniversity.edu.in/about-us/"
        />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="ISBM University Empowering Through Affordable Education"
        />
        <meta
          name="twitter:description"
          content="ISBM University is one of the best university for affordable academic excellence for diploma & masters programs empowering students through quality education."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="abtinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Empowering Futures: ISBM University</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>About Us</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
          <h2 className="linkabout123">About Us</h2>

            <div className="row gy-4">
              <div className="col-lg-5 position-relative about-img"></div>
              <div className="col-lg-7 d-flex align-items-end">
                <div className="content ps-0 ps-lg-5 text-justify">
                  <p className="font-weight-bold" style={{ color: "#A82682" }}>
                    The University is established and approved by the Government
                    of Chhattisgarh under the Chhattisgarh Private Universities
                    (Establishment and Operation) Act, 2005 (Amendment Act 2016)
                    to confer Certificate Diploma, PG Diploma, Degree and
                    Doctorate Degree to the students.
                  </p>
                  <p>
                    ISBM University programmes are research driven, skill based
                    and knowledge intensive. The University is a centre of
                    academic innovation and research where students and faculty
                    collaborate to learn, research, create, innovate, inspire
                    and incubate.
                  </p>
                  <p>
                    ISBM University endeavors to offer services across the
                    student's life cycle not only supported by teaching and
                    learning but also with career development, skill
                    enhancement, employment opportunities, business, incubation
                    and fund raising for development initiatives. It is
                    committed to prepare its students to face the challenges of
                    new age society. It also strives to improve the quality of
                    employment of the people by academic interventions.
                  </p>

                  <div className="position-relative mt-4">
                    <img
                      src={about}
                      className="img-fluid"
                      alt="Welcome to ISBM university"
                    />
                    <a
                      href={"https://www.youtube.com/embed/jMTzToBXj0Q"}
                      className=" play-btn"
                    ></a>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-3 col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="fa fa-line-chart"></i>
                  <h3 className="font-weight-bold text-purple abouttext">
                    Focused on the Overall Development of the Students
                  </h3>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="fa fa-graduation-cap"></i>
                  <h3 className="font-weight-bold text-purple abouttext">
                    Committed towards providing the best in Education
                  </h3>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="fa fa-flask" aria-hidden="true"></i>
                  <h3 className="font-weight-bold text-purple abouttext">
                    Promoting the Research Activities in the University
                  </h3>
                </div>
              </div>
              <div
                className="col-xl-3 col-lg-3 col-md-6"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                  <i className="fa fa-trophy"></i>
                  <h3 className="font-weight-bold text-purple abouttext">
                    Preparing the Students for Globalized World
                  </h3>
                </div>
              </div>
            </div>

            {/* <div className="row gy-4 py-5">
              <div className="col-lg-4 bg-blue text-white">
                <div className="whybox">
                  <h2 className="font-weight-bold">
                    Why Choose ISBM University?
                  </h2>
                  <p className="mb-30px">
                    ISBM University is a new age University to provide
                    industry-driven programmes in Management | Commerce |
                    Science | Engineering & Technology | Information Technology
                    | Design | Pharmacy Life Science / Yoga & Naturopathy | Arts
                    & Humanities | Law | Library & Information Science |
                    Journalism & Mass Communication | Education | Hotel
                    Management | Health & Allied Science | Vocational Management
                    | Media & Entertainment in order to meet the requirements of
                    the industry.
                  </p>
                </div>
              </div>

              <div className="col-lg-8 d-flex align-items-center">
                <div className="row gy-4"></div>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
      <section>
        <div className="container">
          <h2 className="font-weight-bold mt-5">Why Choose ISBM University?</h2>
          <p>
            At ISBM University, we are committed to developing leaders who are
            not merely skilled professionals but also compassionate people with
            strong ethical values.
          </p>
          <div className="row">
            <div className="col-xl-8 col-lg-7 col-md-7 col-sm-12 mb-3">
              <div className="bg-blue p-4 h-100">
                <div className="row mb-3 mx-0 pb-1 border-bottom">
                  <span className="awardlogo2"></span>
                  <h4 className="tab-title">Academic Excellence</h4>
                </div>
                <Col lg={8} className="accordionPadding">
                  <p className="adv">Accredited Programs:</p>
                  <p className="advPara">
                    ISBM University offers a range of programs that are
                    accredited and recognized, ensuring that your degree holds
                    value.
                  </p>
                  <p className="adv">Experienced Faculty:</p>
                  <p className="advPara">
                    The university boasts a team of experienced and qualified
                    faculty members who provide quality education and
                    mentorship.
                  </p>
                  <p className="adv">Industry-Relevant Curriculum:</p>
                  <p className="advPara">
                    The curriculum is designed to meet industry standards and
                    demands, ensuring that students are well-prepared for the
                    job market.
                  </p>
                </Col>
              </div>
            </div>

            <div className="col-xl-4 col-lg-5 col-md-5 col-sm-12 mb-3">
              <div className="bg-blue p-4 h-100">
                <div className="row mb-3 mx-0 pb-1 border-bottom">
                  <span className="awardlogo2"></span>
                  <h4 className="tab-title">Infrastructure and Facilities</h4>
                </div>
                <Col lg={8} className="accordionPadding">
                  <p className="adv">Modern Campus: </p>
                  <p className="advPara">
                    The university has a well-equipped campus with modern
                    facilities including Central Library, laboratories, and ICT
                    enable Classes.
                  </p>
                  <p className="adv">Technological Integration: </p>
                  <p className="advPara">
                    Classrooms and labs are equipped with the latest technology
                    to enhance the learning experience.
                  </p>
                </Col>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="bg-blue p-4 h-100">
                <div className="row mb-3 mx-0 pb-1 border-bottom">
                  <span className="awardlogo2"></span>
                  <h4 className="tab-title">Career Opportunities</h4>
                </div>
                <Col lg={8} className="accordionPadding">
                  <p className="adv">Placement Support: </p>
                  <p className="advPara">
                    ISBM University offers strong placement support with a
                    network of reputed companies and organizations for
                    internships and job placements.
                  </p>
                  <p className="adv">Industry Connections: </p>
                  <p className="advPara">
                    The university maintains strong tie ups with industry
                    leaders, providing students with opportunities for
                    networking and professional growth.
                  </p>
                </Col>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="bg-blue p-4 h-100">
                <div className="row mb-3 mx-0 pb-1 border-bottom">
                  <span className="awardlogo2"></span>
                  <h4 className="tab-title">Holistic Development</h4>
                </div>
                <Col lg={8} className="accordionPadding">
                  <p className="adv">Extracurricular Activities: </p>
                  <p className="advPara">
                    A range of extracurricular activities including clubs,
                    sports, and cultural events are available to ensure holistic
                    development.
                  </p>
                  <p className="adv">Skill Development: </p>
                  <p className="advPara">
                    Focus on soft skills, leadership, and entrepreneurship to
                    ensure well-rounded personal and professional growth.
                  </p>
                </Col>
              </div>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-3">
              <div className="bg-blue p-4 h-100">
                <div className="row mb-3 mx-0 pb-1 border-bottom">
                  <span className="awardlogo2"></span>
                  <h4 className="tab-title">Flexible Learning Options:</h4>
                </div>
                <Col lg={8} className="accordionPadding">
                  <p className="adv">Customized Programs: </p>
                  <p className="advPara">
                    The university offers customized programs and electives that
                    allow students to tailor their education to their career
                    goals.
                  </p>
                </Col>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="stats-counter" className="stats-counter">
        <div className="container">
          <h2>Creating an Inspiring Environment for Learning and Growth</h2>
          <div className="row gy-4">
            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="wifi"></span>
                <p>Wifi Campus</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="greenCampus"></span>
                <p>Lush & Green Campus</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="learningEvn"></span>
                <p>Apt Learning Environment</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="sportsFacility"></span>
                <p>Sports Facilities</p>
              </div>
            </div>
          </div>
          <div className="row gy-4">
            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="temple"></span>
                <p>Temple</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="recreationRoom"></span>
                <p>Recreation Room</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="seminar"></span>
                <p>Seminar Hall</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6">
              <div className="stats-item text-center w-100 h-100">
                <span className="hostel"></span>
                <p>Girls & Boys Hostels</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="placement_sec1">
        <h2>Choose a University Known for Top Placements</h2>
        <section className="placement_sec"></section>
        <h6 className="position-absolute top-50 ms-3 text-white fst-italic p-2">
          Connect with Industry-leading
          <br /> Placement Opportunities
        </h6>
      </section>
      <section className="mob_placement_sec1">
        <h2 className="text-white font-weight-bold text-center mb-3">
          Choose a University Known for Top Placements
        </h2>
        <section className="mob_placement_sec"></section>
      </section>
      <Footer />
    </>
  );
};

export default Aboutus;
