import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from"../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow3";



const Cocstudents = () => {
  const [modalShow, setModalShow] = React.useState(false);
useEffect(() => {
  window.scrollTo(0, 0);
}, []);
    return (
      <>
     
        <Topnav />
        <Menubar />
        <div className="codeofconductinnerbackimage innerbackimage">
          <div className="text_banners_abcds">
            <h1>Code of Conduct (Student)</h1>
            <div  onClick={() => setModalShow(true)}>
              <span>
                <a className="btn btn-primary" href="#" role="button">
                  Apply Now
                </a>
              </span>
            </div>
            <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </div>

        {/* container */}
        <Container className="About_us11">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item active>Code of Conduct (Student)</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Sidebaar />
            <Col lg={9}>
            <h2 className="linkabout123">
              CAMPUS CODE OF CONDUCT FOR STUDENTS
            </h2>
            <ol type="1">
              <li>
                Students planning to participate in a tour or picnic may request
                for relaxation of the dress code by submitting a letter of
                intention certified by their parent or guardian. Upon approval,
                the student will be issued a permission, which must be kept
                along with the ID card and presented to the authorities on
                demand during the period of tour for dress code relaxation.
              </li>
              <li>
                A six-day working schedule from Monday to Saturday is followed.
                Classes are scheduled from 9.15 a.m. to 3.00 p.m.
              </li>
              <li>
                Students shall rise from their seats when the teacher enters the
                classroom and remain standing till the teacher takes her/his
                seat or they are allowed by the teacher to sit. Silence shall be
                observed during class hours.
              </li>
              <li>
                Teachers shall be greeted appropriately with "Good Morning
                Sir/Madam" or "Good Afternoon Sir/Madam" and a "Thank you
                Sir/Madam" when the teacher leaves the classroom.
              </li>
              <li>
                No student shall enter or leave the classroom when the session
                is on without the permission of the teacher concerned.
              </li>
              <li>
                Students having lab sessions in the AN, have to report to the
                lab directly in time and they are not expected to go to the
                classroom.
              </li>
              <li>
                In events of Student seminars/project presentations, etc., it is
                compulsory that all the students of the concerned class be
                present for the entire session.
              </li>
              <li>
                Students can leave the campus during class hours only after
                getting a gate pass from the Principal, HoD, or the tutor and
                after making an entry in the Gate Register maintained by the
                gatekeeper.
              </li>
              <li>
                All students shall leave the classes immediately after 4.15 p.m.
                No students shall wander or gather in the verandah, corridor,
                and staircase, etc. Do not spend much time in canteen, coffee
                shops, etc.
              </li>
              <li>
                All leave applications (Regular) shall be submitted in time, for
                sanction by the HoD and concerned teachers. The application for
                medical leave shall be accompanied by valid medical
                certificates.
              </li>
              <li>
                Students shall come to the college in approved uniforms with
                formal shirts tucked into trousers with a black belt and black
                shoes or semi-covered shoes. Hawaii-type rubber or plastic
                chappals are not permitted. In addition, lady students will have
                a blazer over their shirts. Besides, those who are in
                laboratories shall wear their respective lab-coat.
              </li>
              <li>
                Students shall not make any alterations to the Uniform issued
                from the college without the permission of the concerned HoD,
                and any violation of this will attract a fine of Rs 300, and
                repetition of this could result in suspension from the
                university.
              </li>
              <li>
                All the students are expected to attend all college functions in
                college uniform unless otherwise specified.
              </li>
              <li>
                All the students shall wear their identity cards, well
                displayed. The identity badge is a public document and any
                teaching staff and non-teaching staff shall have the right to
                peruse it. Denial of it will attract disciplinary action.
              </li>
              <li>
                Students planning to make a tour, picnic can avail relaxation in
                the dress code by producing a letter of intention certified by
                their parent/guardian. The student will be issued a permission
                token to be kept along with the ID card to be shown to the
                authorities on demand during the period of the dress code
                relaxation.
              </li>
              <li>
                All kinds of tattooing, body piercing, hairstyling, etc. that go
                against the existing social norms and which could go against the
                spirit of the academic values will be referred to the discipline
                committee.
              </li>
              <li>
                Any dress code violation noticed within the campus will attract
                a fine of Rs.300 coupled with disciplinary actions.
              </li>
              <li>
                As per the Govt. order, students shall not bring powered
                vehicles inside the campus.
              </li>
              <li>
                Students are expected to maintain silence in the academic
                buildings as the decorum, and any deviant behaviour such as
                hooting, whistling, loitering, etc. will be treated as an
                instance of indiscipline.
              </li>
              <li>
                For independent study, students are expected to use the reading
                rooms, library, or the demarcated areas of the academic building
                and shall not resort to sitting in staircases or circulation
                areas where they could interrupt the free movement of the
                academic community.
              </li>
              <li>
                Students are encouraged to make use of the library, common
                computing facilities and to involve in professional body
                activities or any programme authorized by the college beyond class
                hours. However, under normal circumstances, students shall
                retreat to their hostels or residences by 6.00 PM.
              </li>
              <li>
                Access to academic buildings beyond the above timing and on
                holidays without written permission from the concerned HoD will
                be treated as a case of indiscipline.
              </li>
              <li>
                All the students are advised to follow Mobile phone use policy
                and bring it to the notice of the authorities in case of
                violations.
              </li>
              <li>
                Accessing Social Networking Sites such as Facebook, Twitter,
                etc. is prohibited in the campus, however, access to these sites
                is permitted in the hostel building during time slots specified
                by the hostel wardens.
              </li>
              <li>
                Keeping the campus clean is an obligation of the student. Waste
                in the campus is to be kept in the waste baskets. Any violation
                of this shall attract a minimum fine of Rs. 100/- per violation
                with a warning.
              </li>
              <li>
                Consumption of intoxicants / psychotropic substances in any form
                or smoking or using chewing gum, panmasala, etc. are strictly
                prohibited in the campus.
              </li>
              <li>
                It is strongly advised to refrain from activities such as
                scribbling or noting on walls, doors, or furniture which could
                deface the college and destroy the academic ambiance.
              </li>
              <li>
                The students are directed to handle the furniture, equipment,
                fixtures, and appliances of the college and lab carefully. The
                students are also required to follow safety precautions near
                moving machines and electrical installations. In the event of
                damage to property, the responsible students will have to bear
                the cost of replacement/repair with a fine.
              </li>
              <li>
                Students are not permitted to arrange any unauthorized
                celebrations and decorations of any in the campus.
              </li>
              <li>
                Students are not permitted to distribute or display (both
                physically and electronically) material such as notices,
                banners, X'mas stars, etc. in the campus without the permission
                of the competent authority.
              </li>
              <li>
                Students are expected to make use of academic, co-curricular and
                extracurricular facilities available to an optimum level. This
                will certainly make them physically fit, academically competent,
                mentally alert, and socially sensitive.
              </li>
              <li>
                Students who intend to represent the campus or college in
                interuniversity events shall take prior permission from the
                Registrar, and the selection will be based on parameters such as
                academic performance, attendance, character, existing academic
                assignment, and competence of the student in the proposed event
                for participation.
              </li>
              <li>
                On-duty leave applications will not be entertained beyond 10
                working days from the actual date of leave under any
                circumstance. The maximum number of on-duty leave admissible is
                restricted to 10 days per semester.
              </li>
              <li>
                During the period of internal examinations, students are not
                allowed to leave the hall within one hour from the beginning of
                the exam, and students have to occupy the seat 5 minutes before
                the commencement of the examination.
              </li>
              <li>
                The third series test will be optional, and the students who
                desire to attend this need to register well in advance with the
                concerned member of faculty. The syllabus for the third series
                shall cover 3rd and 4th modules from the university syllabus.
              </li>
              <li>
                Political activity in any form is not permitted in the
                university campus. Unauthorized meetings, propaganda,
                processions, or fund collections are forbidden within the
                Campus, hostels, and outside the campus.
              </li>
              <li>
                Harassing juniors, ill-treatment to other fellow students, or
                any form of ragging is objectionable and liable to be treated as
                a criminal offense by the law enforcing agencies as per the
                directives of Hon'ble Supreme Court of India.
              </li>
              <li>
                Misbehaviour female students, threatening or violence against
                members of the staff or fellow students will be considered as
                serious cases of misconduct.
              </li>
              <li>
                The students are required to pay fees, mess bills, etc. in
                advance or in time, which will avoid fines.
              </li>
              <li>
                Any violation of the above rules will invite a penalty in the
                form of warning, fine, calling parents, or any course of
                corrective measure as found suitable by the discipline
                committee.
              </li>
              <li>
                Expulsion from the University is also expulsion from the hostel.
              </li>
              <li>
                In case of any instance of non-compliance of existing rules or
                any observed matter/behaviour that deviates from the vision and
                mission of the university, all the staff members are empowered
                to initiate disciplinary procedure by filling in a standard form
                which in turn may lead to the constitution of an inquiry
                committee and further proceedings.
              </li>
            </ol>
            <li>
              <h className="font-weight-bold fs-5">
                Fine policy of ISBM University :
              </h>
              The money collected from the students as a fine on instances of
              non-compliance with the rules and norms will be kept in a separate
              account and will be utilized for purposes such as charity,
              scholarship, student co-curricular activities, and
              renovation/maintenance in case of damage to the property.
            </li>
            <h className="font-weight-bold  fs-5" >
              1. Mobile Phone Use Policy: Introduction
            </h>
            <p>
              The purpose of this policy is to establish a guideline regarding
              the usage of mobile phones inside the campus. This policy is
              applicable to all those who enter the campus.
            </p>
            <h className="font-weight-bold  fs-5" >
              2. Mobile Phone Use Policy
            </h>
            <ol type="1">
              <li>
                Realizing the importance of communication and the possible
                impact of mobile phone usage inside the campus, the following
                guidelines are arrived at.
              </li>
              <li>
                Students are not permitted to use mobile phones within the
                campus.
              </li>
              <li>
                Students who are staying in the university hostels should not
                bring the mobile phones to the university.
              </li>
              <li>
                Day scholars carrying mobile phones to the college shall deposit
                the same in the designated place before 8.55 AM and can collect
                the same after 4.10 PM.
              </li>
              <li>
                The mobile phones shall be kept in silent mode while depositing
                in the designated place.
              </li>
              <li>
                All the staff members are empowered to confiscate mobile phones
                found with students violating the above rules.
              </li>
              <li>
                Students violating the rules will be fined an amount of Rs
                1000/- and the confiscated mobile phones will be returned to the
                parents of the students.
              </li>
              <li>
                Staff members are allowed to use mobile phones in their
                respective cabins.
              </li>
            </ol>
            </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  
}

export default Cocstudents;
