import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import honor from "../../Images/Hon'ble Persons/Hon’ble Shri Raman Deka.webp";
import EnquiryNow from "../common/EnquiryNow3";
import { MetaTags } from "react-meta-tags";

const Visitor = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <MetaTags>
        <title>Hon'ble Visitor of ISBM University</title>
        <meta
          name="description"
          content="Mr. Biswabhusan Harichandan is The Hon'ble Visitor of ISBM University. Explore ISBM University a hallmark of recognized excellence in quality and affordable education."
        />
        <link rel="canonical" href="https://isbmuniversity.edu.in/honorable" />
        <meta
          name="SearchEngines"
          content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
        DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
        />
        <meta name="Robots" content="INDEX, FOLLOW" />
        <meta name="Author" content="ISBM University, isbmuniversity.edu.in/" />
        <meta name="Robots" content="INDEX,ALL" />
        <meta name="YahooSeeker" content="INDEX, FOLLOW" />
        <meta name="msnbot" content="INDEX, FOLLOW" />
        <meta name="googlebot" content="Default, follow" />
        <meta name="owner" content="isbmuniversity.edu.in/" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta
          property="og:title"
          content="Hon'ble Visitor of ISBM University "
        />
        <meta
          property="og:description"
          content="Mr. Biswabhusan Harichandan is The Hon'ble Visitor of ISBM University. Explore ISBM University a hallmark of recognized excellence in quality and affordable education."
        />
        <meta
          property="og:url"
          content="https://isbmuniversity.edu.in/honorable/"
        />
        <meta
          property="og:image"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta property="og:image:alt" content="ISBM University" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ISBM University" />
        <meta
          name="twitter:title"
          content="Hon'ble Visitor of ISBM University"
        />
        <meta
          name="twitter:description"
          content="Mr. Biswabhusan Harichandan is The Hon'ble Visitor of ISBM University. Explore ISBM University a hallmark of recognized excellence in quality and affordable education."
        />
        <meta
          name="twitter:image:src"
          content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@ISBMUniversity" />
        <meta name="twitter:creator" content="@ISBMUniversity" />
        <meta name="twitter:domain" content="https://isbmuniversity.edu.in/" />
        <meta property="twitter:account_id" content="758619542734053376" />
        <meta
          name="keywords"
          content="Professional Degrees , Recognized University , Accredited College"
        />
      </MetaTags>
      <Topnav />
      <Menubar />
      <div className="Visitorinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Hon'ble Visitor</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Hon'ble Visitor</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
          <h2 className="linkabout123">Hon'ble Visitor</h2>

            <Row>
              <Col lg={4}>
                <div className="honor_div">
                  <img
                    className="img_honor"
                    src={honor}
                    alt="Hon. Governor of Chhattisgarh Mr.Biswabhusan Harichandan"
                  />
                </div>
              </Col>
              <Col lg={8}>
                <h2 className="honor_name">Hon’ble Shri Raman Deka </h2>
                <p className="text-justify">
                  Hon’ble Shri Raman Deka Ji serving as the Governor of
                  Chhattisgarh, bringing with him a wealth of experience and
                  dedication to public service. As a prominent political leader,
                  Shri Raman Deka Ji has a distinguished career, marked by his
                  commitment to the development and welfare of the people. His
                  tenure as Governor is characterized by initiatives aimed at
                  improving governance, enhancing infrastructure, and fostering
                  economic growth in the state. Shri Raman Deka Ji leadership is
                  pivotal in addressing key issues such as education,
                  healthcare, and rural development, ensuring that the benefits
                  of progress reach every segment of society. His collaborative
                  approach with various stakeholders, including government
                  officials, industry leaders, and civil society, underscores
                  his vision of a prosperous and inclusive Chhattisgarh. Through
                  his efforts, Hon’ble Shri Raman Deka Ji continues to play a
                  crucial role in steering the state towards sustainable
                  development and socio-economic advancement. He significantly
                  influences the state’s development trajectory. His tenure is
                  marked by a blend of visionary leadership, pragmatic
                  governance, and a deep-seated commitment to public welfare. As
                  Governor, Shri Raman Deka has been instrumental in
                  implementing policies and initiatives that aim to transform
                  Chhattisgarh into a model state in India
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Visitor;
