import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow3";

const CocHostel = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="codeofconductinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Code of Conduct <br/>(Boarders of Hostel)</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>
            Code of Conduct (Boarders of Hostel)
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">
              CODE OF CONDUCT FOR THE BOARDERS OF HOSTEL
            </h2>
            <p>
              <span className="font-weight-bold fs-5">Introduction:</span> 
              The boarders of the hostels are expected to uphold high standards of ethical and moral behavior. The following code of conduct is outlined for them.
            </p>
            <h3 className="font-weight-bold fs-5">General Conduct</h3>

            <ul>
              <h4 className="font-weight-bold fs-5">Respect for Others</h4>
              <li>
                Treat all residents, staff, and visitors with respect, kindness, and courtesy.
              </li>
              <li>
                Avoid engaging in behavior that may cause harm, discomfort, or distress to others.
              </li>
              <li>
                Respect the privacy and personal space of fellow residents.
              </li>
            </ul>
            <ul>
              <h4 className="font-weight-bold fs-5">Quiet Hours</h4>
              <li>
                Quiet hours are enforced from 9:30 PM to 7:00 AM every day.
              </li>
              <li>
                Keep noise levels to a minimum during quiet hours to ensure a healthy environment for studying and rest.
              </li>
            </ul>
            <ul>
              <h4 className="font-weight-bold fs-5">Cleanliness</h4>
              <li>Keep your living space clean and tidy at all times.</li>
              <li>
                Dispose of garbage properly and follow recycling guidelines.
              </li>
              <li>
                Report any maintenance issues promptly to the hostel admin or warden.
              </li>
            </ul>
            <ul>
              <h4 className="font-weight-bold fs-5">Safety and Security</h4>
              <li>
                Do not compromise the safety and security of yourself or others.
              </li>
              <li>
                Always lock your room when leaving and do not prop doors open.
              </li>
              <li>
                Do not allow unauthorized individuals to enter the hostel premises.
              </li>
            </ul>
            <ul>
              <h4 className="font-weight-bold fs-5">Use of Alcohol and Toxic Substances</h4>
              <li>
                The consumption of alcohol and use of any illegal toxic substances are strictly prohibited in the hostel.
              </li>
              <li>
                Do not bring or store alcohol or any illegal toxic substances on hostel premises.
              </li>
            </ul>
            <ul>
              <h4 className="font-weight-bold fs-5">Smoking</h4>
              <li>
                Smoking is strictly prohibited within the hostel building, including individual rooms.
              </li>
            </ul>
            <ul>
              <h4 className="font-weight-bold fs-5">Compliance with University Policies</h4>
              <li>
                Adhere to all university policies and regulations, including those related to conduct, academic integrity, and proper use of facilities.
              </li>
            </ul>
            <ul>
              <h4 className="font-weight-bold fs-5">Disciplinary Action</h4>
              <li>
                Violation of the hostel code of conduct will result in disciplinary action, including warnings, fines, suspension of hostel privileges, or expulsion from the hostel. Serious or repeated violations may also result in disciplinary action by the university.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default CocHostel;
