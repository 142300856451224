import React, { useEffect, useState } from "react";
import { Accordion, Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import media1 from "../../Images/media/media 1.webp";
import media2 from "../../Images/media/media 2.webp";
import media3 from "../../Images/media/media 3.webp";
import media4 from "../../Images/media/media 4.webp";
import media5 from "../../Images/media/media 5.webp";
import media6 from "../../Images/media/media 6.webp";
import media7 from "../../Images/media/media 7.webp";
import media8 from "../../Images/media/media 8.webp";
import media9 from "../../Images/media/media 9.webp";
import media10 from "../../Images/media/media 10.webp";
import media11 from "../../Images/media/media 11.webp";
import media12 from "../../Images/media/media 12.webp";
import media13 from "../../Images/media/media 13.webp";
import media14 from "../../Images/media/media 14.webp";
import media15 from "../../Images/media/media 15.webp";
import media16 from "../../Images/media/media 16.webp";
import EnquiryNow from "../common/EnquiryNow3";
import ImageModal from "./ImageModal";

const Media = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [modalShow1, setModalShow1] = useState(false);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="mediainnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Media Coverage</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Media Coverage</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9} className="media_videos">
          <h2 className="linkabout123">Media Coverage</h2>

            <div className="international_block">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active display-6 p-3"
                    id="nav-home-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-home"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    <i
                      className="fa fa-video-camera me-2"
                      aria-hidden="true"
                    ></i>
                    Media Coverage
                  </button>
                  <button
                    className="nav-link display-6 p-3"
                    id="nav-profile-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profile"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    <i className="fa fa-newspaper-o me-2" aria-hidden="true"></i>
                    Newspaper Coverage
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <Row>
                    <Col lg={4} className="pb-4">
                      <div className="youvideos">
                        <iframe
                          style={{ width: "100%", height: "250px" }}
                          src="https://www.youtube.com/embed/MWLWh0hgGa0"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                        <h4>Media Coverage of Futsal League</h4>
                      </div>
                    </Col>

                    <Col lg={4} className="pb-4">
                      <div className="youvideos">
                        <iframe
                          style={{ width: "100%", height: "250px" }}
                          src="https://www.youtube.com/embed/VO2P-OGklh8"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <h4>Media Coverage of Glibs.in news</h4>
                      </div>
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div className="youvideos">
                        <iframe
                          style={{ width: "100%", height: "250px" }}
                          src="https://www.youtube.com/embed/qxvf9KPvKAw"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <h4>Media Coverage of Swaraj Express</h4>
                      </div>
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div className="youvideos">
                        <iframe
                          style={{ width: "100%", height: "250px" }}
                          src="https://www.youtube.com/embed/HqxxM7QkteM"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>

                        <h4>Media Coverage of Swaraj Express</h4>
                      </div>
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div className="youvideos">
                        <iframe
                          style={{ width: "100%", height: "250px" }}
                          src="https://www.youtube.com/embed/jgS_lbVI428"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <h4>Media Coverage of IBC24 News</h4>
                      </div>
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div className="youvideos">
                        <iframe
                          style={{ width: "100%", height: "250px" }}
                          src="https://www.youtube.com/embed/490Sw_wiZW8"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <h4>Media Coverage of Desh TV</h4>
                      </div>
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div className="youvideos">
                        <iframe
                          style={{ width: "100%", height: "250px" }}
                          src="https://www.youtube.com/embed/d-RIDjYSYFE"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <h4>Media Coverage of Swaraj Express</h4>
                      </div>
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div className="youvideos">
                        <iframe
                          style={{ width: "100%", height: "250px" }}
                          src="https://www.youtube.com/embed/d-H0D7OaRp0"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <h4>Media Coverage of Edumate TV</h4>
                      </div>
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div className="youvideos">
                        <iframe
                          style={{ width: "100%", height: "250px" }}
                          src="https://www.youtube.com/embed/msaIBvKXjws"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                        <h4>Media Coverage of IBC24</h4>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className="tab-pane fade"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <Row>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media16}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media1}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media2}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media3}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media4}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media5}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media6}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media7}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media8}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media9}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media10}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media11}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media12}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media13}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media14}
                      />
                    </Col>
                    <Col lg={4} className="pb-4">
                      <div onClick={() => setModalShow1(true)}></div>
                      <ImageModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        imageUrl={media15}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
            <div className="international_block2">
              <Accordion className="" defaultActiveKey="0">
                <Accordion.Item className="advantages2" eventKey="0">
                  <Accordion.Header>
                    <i
                      className="fa fa-video-camera me-2"
                      aria-hidden="true"
                    ></i>
                    Media Coverage
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col lg={4} className="pb-4">
                        <div className="youvideos">
                          <iframe
                            style={{ width: "100%", height: "250px" }}
                            src="https://www.youtube.com/embed/msaIBvKXjws"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                          <h4>Media Coverage of IBC24</h4>
                        </div>
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div className="youvideos">
                          <iframe
                            style={{ width: "100%", height: "250px" }}
                            src="https://www.youtube.com/embed/r-z-6sMtrgY"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                          <h4>Media Coverage of Swaraj Express</h4>
                        </div>
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div className="youvideos">
                          <iframe
                            style={{ width: "100%", height: "250px" }}
                            src="https://www.youtube.com/embed/d-H0D7OaRp0"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                          <h4>Media Coverage of Edumate TV</h4>
                        </div>
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div className="youvideos">
                          <iframe
                            style={{ width: "100%", height: "250px" }}
                            src="https://www.youtube.com/embed/490Sw_wiZW8"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                          <h4>Media Coverage of Desh TV</h4>
                        </div>
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div className="youvideos">
                          <iframe
                            style={{ width: "100%", height: "250px" }}
                            src="https://www.youtube.com/embed/VO2P-OGklh8"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                          <h4>Media Coverage of Glibs.in news</h4>
                        </div>
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div className="youvideos">
                          <iframe
                            style={{ width: "100%", height: "250px" }}
                            src="https://www.youtube.com/embed/d-RIDjYSYFE"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>

                          <h4>Media Coverage of Swaraj Express</h4>
                        </div>
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div className="youvideos">
                          <iframe
                            style={{ width: "100%", height: "250px" }}
                            src="https://www.youtube.com/embed/HqxxM7QkteM"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen
                          ></iframe>
                          <h4>Media Coverage of Swaraj Express</h4>
                        </div>
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item className="advantages2" eventKey="2">
                  <Accordion.Header>
                    <i
                      className="fa fa-newspaper-o me-2"
                      aria-hidden="true"
                    ></i>
                    Newspaper Coverage
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media16}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media1}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media2}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media3}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media4}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media5}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media6}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media7}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media8}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media9}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media10}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media11}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media12}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media13}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media14}
                        />
                      </Col>
                      <Col lg={4} className="pb-4">
                        <div onClick={() => setModalShow1(true)}></div>
                        <ImageModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          imageUrl={media15}
                        />
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Media;
