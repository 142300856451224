import React, { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import $ from "jquery";
import laptopgirl from "../../Images/commonimg/english_teacher_doing_her_lessons_online_1_634dad1575.webp";

function EnquiryNow4({ show, onHide }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [courses, setCourses] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useEffect(() => {
    if (show) {
      const fetchStates = () => {
        $.ajax({
          type: "POST",
          url: "https://insityapp.com/dbFiles/fetch_states.php",
          dataType: "json",
          success: function (response) {
            setStates(response.states || []);
          },
          error: function (error) {
            console.error("Error fetching states:", error);
          },
        });
      };

      const fetchLevels = () => {
        $.ajax({
          type: "POST",
          url: "https://insityapp.com/dbFiles/fetch_levels.php",
          data: { entity: "ISBMU" },
          dataType: "json",
          success: function (response) {
            setLevels(response.levels || []);
          },
          error: function (error) {
            console.error("Error fetching levels:", error);
          },
        });
      };

      fetchStates();
      fetchLevels();
    }
  }, [show]);

  useEffect(() => {
    if (selectedState) {
      $.ajax({
        type: "POST",
        url: "https://insityapp.com/dbFiles/fetch_cities.php",
        data: { state: selectedState },
        dataType: "json",
        success: function (response) {
          setCities(response.cities || []);
        },
        error: function (error) {
          console.error("Error fetching cities:", error);
        },
      });
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedLevel) {
      $.ajax({
        type: "POST",
        url: "https://insityapp.com/dbFiles/fetch_courses.php",
        data: { level: selectedLevel, entity: "ISBMU" },
        dataType: "json",
        success: function (response) {
          setCourses(response.courses || []);
        },
        error: function (error) {
          console.error("Error fetching courses:", error);
        },
      });
    }
  }, [selectedLevel]);

  const handleSubmit = (e) => {
    setIsSubmitting(true);
    // Gather form data
    const formData = new FormData(e.target);

    // Send form data using fetch
    fetch("https://isbmuniversity.edu.in/submitLead.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          console.log("Form submitted successfully");
          // Handle successful submission
        } else {
          console.error("Form submission failed");
          // Handle submission failure
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setTimeout(() => {
          setIsSubmitting(false); // Reset button after submission is complete
        }, 2000); // Example delay - replace with actual form submission logic
      });
  };

  return (
    <Modal
      className="enquiry_formss"
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="custom-modal modal-xl"
    >
      <Modal.Header closeButton style={{ display: "end" }} />
      <Modal.Body className="abcd_modal_abcd mb-4" style={{ borderRadius: "2rem" }}>
        <Row>
          <Col lg={5}>
            <img className="img_enqurymodal" src={laptopgirl} alt="Laptop Girl" />
          </Col>
          <Col lg={7}>
            <form
              action="https://isbmuniversity.edu.in/submitLead.php"
              method="post"
              onSubmit={handleSubmit}
              className="enquiryform"
            >
              <h2 className="enquirymodalheading">Connect With Our Counsellor</h2>
              <p className="enquirymodalheading2">
                "Please provide the information below, and our counselor will get in touch with you."
              </p>
              <Row>
                <div className="form-group col-lg-6">
                  <label className="mt-3 text-blue">Enter Your Name</label>
                  <input
                    type="text"
                    className="form-control form-control2 mb-3"
                    id="name"
                    name="name"
                    placeholder="Enter your Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label className="mt-3 text-blue">Enter Your Email</label>
                  <input
                    type="email"
                    className="form-control form-control2 mb-3"
                    id="email"
                    name="email"
                    placeholder="Enter your Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </Row>
              <Row>
                <div className="form-group col-lg-6 mb-3">
                  <label className="mt-2 text-blue">Enter Your Number</label>
                  <input
                    type="number"
                    className="form-control form-control2"
                    id="mobile_code"
                    name="phone"
                    placeholder="Phone Number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group col-lg-6 mb-3">
                  <label className="mt-2 text-blue">Select Your State</label>
                  <select
                    className="form-select form-control2"
                    id="state"
                    name="state"
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    required
                  >
                    <option value="">Select State</option>
                    {states.length > 0 && states.map(state => (
                      <option key={state} value={state}>{state}</option>
                    ))}
                  </select>
                </div>
              </Row>
              <Row>
                <div className="form-group col-lg-6 mb-3">
                  <label className="mt-2 text-blue">Select Your City</label>
                  <select
                    className="form-select form-control2"
                    id="city"
                    name="city"
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                    required
                  >
                    <option value="">Select City</option>
                    {cities.length > 0 && cities.map(city => (
                      <option key={city} value={city}>{city}</option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-lg-6 mb-3">
                  <label className="mt-2 text-blue">Degree you are looking for *</label>
                  <select
                    className="form-select form-control2"
                    id="level"
                    name="level"
                    value={selectedLevel}
                    onChange={(e) => setSelectedLevel(e.target.value)}
                    required
                  >
                    <option value="">Select Level</option>
                    {levels.length > 0 && levels.map(level => (
                      <option key={level} value={level}>{level}</option>
                    ))}
                  </select>
                </div>
              </Row>
              <div className="form-group mb-3">
                <label className="mt-2 text-blue">Programs you are looking for *</label>
                <select
                  className="form-select form-control2"
                  id="course"
                  name="course"
                  value={selectedCourse}
                  onChange={(e) => setSelectedCourse(e.target.value)}
                  required
                >
                  <option value="">Select Course</option>
                  {courses.length > 0 && courses.map(course => (
                    <option key={course} value={course}>{course}</option>
                  ))}
                </select>
              </div>
              <input
                type="text"
                className="form-control form-control2"
                id="current_url"
                name="current_url"
                value={`${window.location.href}`}
                hidden
              />
              <input
                type="text"
                name="utm_source"
                value={
                  new URLSearchParams(window.location.search).get("utm_source") ||
                  ""
                }
                hidden
              />
              <input
                type="text"
                name="utm_medium"
                value={
                  new URLSearchParams(window.location.search).get("utm_medium") ||
                  ""
                }
                hidden
              />
              <input
                type="text"
                name="utm_campaign"
                value={
                  new URLSearchParams(window.location.search).get("utm_campaign") ||
                  ""
                }
                hidden
              />
              <div className="form-check">
                <input
                  className="form-check-input mt-2"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                  required
                />
                <label className="form-check-label text-blue" htmlFor="flexCheckChecked">
                  By clicking on above button, I authorise ISBMU and/or their representative to contact me via Phone, SMS, WhatsApp and/or email.
                </label>
              </div>
              <div className="form-group d-flex justify-content-center">
                <input
                  type="submit"
                  name="submit"
                  value="Submit"
                  className="btn btn-primary mt-3 enquirybutton"
                  disabled={isSubmitting}
                />
              </div>
            </form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EnquiryNow4;
