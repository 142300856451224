import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import award1 from "../../Images/awards/ISBMU-awards1.webp";
import award2 from "../../Images/awards/ISBMU-awards2.webp";
import award3 from "../../Images/awards/ISBMU-awards4.webp";
import award4 from "../../Images/awards/ISBMU-awards5.webp";
import award5 from "../../Images/awards/ISBMU-awards6.webp";
import award6 from "../../Images/awards/ISBMU-awards7.webp";
import award7 from "../../Images/awards/ISBMU-awards8.webp";
import award8 from "../../Images/awards/ISBMU-awards9.webp";
import award9 from "../../Images/awards/ISBMU-awards10.webp";
import award10 from "../../Images/awards/ISBMU-awards11.webp";
import award13 from "../../Images/awards/ISBMU-awards13.webp";

const Awards = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Topnav />
      <Menubar />
      <div className="awardsinnerbackimage innerbackimage"></div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Awards</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
          <h2 className="linkabout123">Awards</h2>
            <ul className="ps-0">
              <li className="awardsbox">
                <div className="awardImg">
                  <img alt="Dainik Bhaskar Eminence Award" src={award13}></img>
                </div>
                <div className="awarddetails">
                  <h3>Dainik Bhaskar Eminence Award</h3>
                  <p>
                    The Hon'ble Vice Chancellor Dr. Anand Mahalwar and Registrar
                    Dr. B.P. Bhol received the Dainik Bhaskar Eminence Award for
                    the recognition of our countless contributions in the field
                    of education.
                  </p>
                </div>
              </li>
              <li className="awardsbox">
                <div className="awardImg">
                  <img
                    alt="The Best University in
                    Chhattisgarh Award"
                    src={award1}
                  ></img>
                </div>
                <div className="awarddetails">
                  <h3>
                    ISBM University is awarded the Best University in
                    Chhattisgarh
                  </h3>
                  <p>
                    ISBM University is awarded the "Best University in
                    Chhattisgarh" by the Honorable CM of Chhattisgarh, Shri
                    Bhupesh Baghel.
                  </p>
                </div>
              </li>

              <li className="awardsbox">
                <div className="awardImg">
                  <img alt="Global Award 2019" src={award2}></img>
                </div>
                <div className="awarddetails">
                  <h3>
                    Global Award 2019 as Best Upcoming University by Auropath
                  </h3>
                  <p>
                    ISBM University is awarded the "Best Upcoming University"
                    Global Award 2019 by Auropath.
                  </p>
                </div>
              </li>

              <li className="awardsbox">
                <div className="awardImg">
                  <img alt="Certificate of Excellence Award" src={award3}></img>
                </div>
                <div className="awarddetails">
                  <h3>
                    ISBM University is awarded with "Certificate of Excellence"
                  </h3>
                  <p>
                    ISBM University is awarded the "Certificate of Excellence"
                    by Dr. Vinod Kumar Bhardwaj, State Nodal Officer,
                    Innovations & Skill Development Programmes, Department of
                    College Education, Government of Rajasthan on the occasion
                    of 11th Higher Education & Human Resource Conclave, Pune.
                  </p>
                </div>
              </li>

              <li className="awardsbox">
                <div className="awardImg">
                  <img alt="The Best University Award" src={award4}></img>
                </div>
                <div className="awarddetails">
                  <h3>
                    ISBM University is awarded Best University in Chhatisgarh -
                    2019
                  </h3>
                  <p>
                    ISBM University is awarded Best University in Chhatisgarh
                    2019 by Shri Bhupesh Baghel, Hon. CM of Chhatisgarh.
                  </p>
                </div>
              </li>

              <li className="awardsbox">
                <div className="awardImg">
                  <img
                    alt="Best University Promoting
                    Government Digital Initiatives Award"
                    src={award5}
                  ></img>
                </div>
                <div className="awarddetails">
                  <h3>
                    ISBM University is awarded Best University Promoting
                    Government Digital Initiatives - 2019
                  </h3>
                  <p>
                    ISBM University has been awarded by ASSOCHAM on its
                    Education Summit 2019 for being the "Best University
                    Promoting Government Digital Initiatives". presented by His
                    Excellency Former President of India Shri Pranab Mukherjee
                    and Hon. Minister for commerce and industry and civil
                    aviation, Government of India "Shri Suresh Prabhu" in Delhi.
                  </p>
                </div>
              </li>

              <li className="awardsbox">
                <div className="awardImg">
                  <img alt="MOST EMERGING UNIVERSITY Award" src={award6}></img>
                </div>
                <div className="awarddetails">
                  <h3>
                    ISBM University is awarded "MOST EMERGING UNIVERSITY 2018"
                  </h3>
                  <p>
                    ISBM University is awarded "MOST EMERGING UNIVERSITY 2018"
                    by Hon. Ex-Chief Minister of Uttarakhand State by "Shri
                    Harish Rawat" at New Delhi.
                  </p>
                </div>
              </li>

              <li className="awardsbox">
                <div className="awardImg">
                  <img
                    alt="Best Upcoming
                    University Award"
                    src={award7}
                  ></img>
                </div>
                <div className="awarddetails">
                  <h3>
                    ISBM University is awarded with the "Best Upcoming
                    University" Award - 2018
                  </h3>
                  <p>
                    ISBM University is awarded "Best Upcoming University" by
                    Shri Purushottam Rupala, Hon'ble Minister of Agriculture and
                    Panchayati Raj at World Education Summit in association with
                    #IndiaToday Group on 27th April 2018.
                  </p>
                </div>
              </li>

              <li className="awardsbox">
                <div className="awardImg">
                  <img alt="The Best University Award" src={award8}></img>
                </div>
                <div className="awarddetails">
                  <h3>
                    ISBM University is awarded "The Best Upcoming University" -
                    2017
                  </h3>
                  <p>
                    ISBM University is awarded "THE BEST UPCOMING UNIVERSITY" by
                    the Hon. HRD Minister 'Shri Prakash Javdekar' in association
                    with ASSOCHAM.
                  </p>
                </div>
              </li>

              <li className="awardsbox">
                <div className="awardImg">
                  <img alt="The Best University Award" src={award9}></img>
                </div>
                <div className="awarddetails">
                  <h3>
                    ISBM University is awarded "MOST INNOVATIVE UNIVERSITY 2018"
                  </h3>
                  <p>
                    ISBM University is awarded "MOST INNOVATIVE UNIVERSITY" by
                    Hon. Chief Minister of Chhattisgarh 'Shri Raman Singh', Hon.
                    Higher Education Minister of Chhattisgarh State 'Shri Prem
                    Prakash Pandey' and Hon. School Education Minister of
                    Chhattisgarh State 'Shri Kedar Kashyap' at Sushiksha event
                    on 25th June in Raipur, in association with News18Network.
                  </p>
                </div>
              </li>

              <li className="awardsbox">
                <div className="awardImg">
                  <img
                    alt="The Best Emerging Private University Award"
                    src={award10}
                  ></img>
                </div>
                <div className="awarddetails">
                  <h3>ISBM University is awarded</h3>
                  <p>
                    DR. D.M. Deshpande (Vice-Chancellor - ISBM University)
                    receiving "The Best Emerging Private University" Award by
                    Shri Raman Singh (Chief Minister - Chhattisgarh) and Shri
                    Narendra Singh Tomar (Union Mines and Rural Development
                    Minister).
                  </p>
                </div>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Awards;
