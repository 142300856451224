import React, { Component } from "react";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import Banner from "./Banner";
import AboutSec from "./AboutSec";
import Features from "./Features";
import ApplySec from "./ApplySec";
import Events from "./Events";
import News from "./News";
import SearchCourse from "./SearchCourse";
import Footer from "../common/footer";
import { MetaTags } from "react-meta-tags";
import EnquiryNow4 from "../common/EnquiryNow4";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: true,
    };
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <>
        <MetaTags>
          {/* Meta tags content */}
        </MetaTags>
        <EnquiryNow4 show={this.state.showModal} onHide={this.closeModal} />
        <Topnav />
        <Menubar />
        <Banner />
        <AboutSec />
        <Features />
        <ApplySec />
        <Events />
        <News />
        <SearchCourse />
        <Footer />
      </>
    );
  }
}

export default Home;
