import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Sidebaar from "../sidebars/AboutSidebar";
import EnquiryNow from "../common/EnquiryNow3";


const Cocfaculty = () => {
  const [modalShow, setModalShow] = React.useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
    

      <Topnav />
      <Menubar />
      <div className="codeofconductinnerbackimage innerbackimage">
        <div className="text_banners_abcds">
          <h1>Code of Conduct (Faculty)</h1>
          <div onClick={() => setModalShow(true)}>
            <span>
              <a className="btn btn-primary" href="#" role="button">
                Apply Now
              </a>
            </span>
          </div>
          <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </div>

      {/* container */}
      <Container className="About_us11">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>

          <Breadcrumb.Item active>Code of Conduct (Faculty)</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Sidebaar />
          <Col lg={9}>
            <h2 className="linkabout123">
              CODE OF PROFESSIONAL ETHICS FOR FACULTY MEMBERS
            </h2>

            <h5 className="font-weight-bold">
              (a) THE TEACHERS AND THEIR PROFESSIONAL RESPONSIBILITIES:
            </h5>
            <p>
              Any person who takes teaching as a profession assumes the
              obligation to conduct himself/herself in accordance with the
              ideals of the profession. The teacher is constantly under the
              scrutiny of his/her students and the society at large. Therefore,
              every teacher shall see that there is no incompatibility between
              his/her precepts and practice. The national ideals of education
              that have already been set forth and which he/she should seek to
              inculcate among students must be his/her ideals, duly reflected in
              his conduct. The profession further requires that the teacher
              shall be calm, patient, and communicative and amiable in
              disposition.
            </p>
            <h className="font-weight-bold fs-5">A teacher shall:</h>
            <ol type="i">
              <li>
                Adhere to a responsible pattern of conduct and demeanor expected
                of him/her by his/her peers and the community.
              </li>
              <li>
                Prefer constructive, reflective, collaborative, integrative and
                enquiry- based approach and pedagogical tools for
                teaching-learning assignment.
              </li>
              <li>
                Communicate effectively while facilitating students and other
                stakeholders and the preferably language is English.
              </li>
              <li>
                Wear smart professional attire at the workplace preferably in
                decent formals.
              </li>
              <li>
                Manage his/her private affairs in a manner consistent with the
                dignity of the profession.
              </li>
              <li>
                Seek to make professional growth continuous through study,
                research, writing, and participating in seminar, workshops,
                symposia with decent conduct.
              </li>
              <li>
                Express free and frank opinion by active participation at
                professional meetings, seminars, conferences, by contribution.
                towards the contribution of knowledge.
              </li>
              <li>
                Maintain active membership in professional organizations,
                subscribing to academic/subject periodicals, and strive to
                improve education and profession.
              </li>
              <li>
                Perform his/her duties in the form of teaching, tutorial,
                practical and seminar work with dedication.
              </li>
              <li>
                Co-operate and assist in carrying out functions relating to the
                educational responsibilities of the University such as:
                assisting and appraising applications for admission, advising
                and counselling students as well as assisting in the conduct of
                University examinations, including supervision, invigilation,
                and evaluation.
              </li>
              <li>
                Participate in extension, co-curricular and extracurricular
                activities including community service.
              </li>
              <li>
                Try to complete assigned task in timely manner less adhering to
                the deadlines assigned.
              </li>
            </ol>
            <h5 className="font-weight-bold">(b) TEACHERS AND THE STUDENTS:</h5>
            <h className="font-weight-bold fs-5">A teacher shall:</h>
            <ol type="i">
              <li>
                Respect the right and dignity of the student while expressing
                his/her opinion.
              </li>
              <li>
                Deal impartially with students regardless of their religion,
                caste, sex, political, economic, social, and physical status.
              </li>
              <li>
                Mark his/her attendance preferably within 10 minutes of the
                commencement of lecture and on the day of the class.
              </li>
              <li>
                Recognize the difference in aptitude and capabilities among
                students and strive to meet their academic needs.
              </li>
              <li>
                Encourage students to improve their attainments, develop their
                personalities and at the same time contributing to community
                welfare.
              </li>
              <li>
                Inculcate among students scientific, progressive, and rational
                outlook and respect for physical labour and ideals of democracy,
                patriotism, and peace.
              </li>
              <li>
                Be affectionate to the students and not behave in a vindictive
                manner towards any student for any reason.
              </li>
              <li>
                Pay attention for the attainment of the student in the
                assessment of merit.
              </li>
              <li>
                Make himself/herself available to the students even beyond their
                class hours to help and guide them without any remuneration or
                reward.
              </li>
              <li>
                Refrain from inciting students against other students,
                colleagues, or administration.
              </li>
            </ol>
            <h5 className="font-weight-bold"> (c) TEACHERS AND COLLEAGUES:</h5>
            <h className="font-weight-bold fs-5">The teachers shall always:</h>
            <ol type="i">
              <li>
                Treat other members of the profession in an equitable and decent
                manner.
              </li>
              <li>
                Speak respect of other teachers and render assistance for
                professional betterment.
              </li>
              <li>
                Refrain from lodging unsubstantiated allegations against
                colleagues to higher authorities.
              </li>
              <li>
                Refrain from exploiting considerations of caste, creed,
                religion, race, or gender in their professional endeavor.
              </li>
              <li>
                Thorough social and humane, democratic and rational, towards
                other teachers.
              </li>
              <li>
                Strive at any cost to remove and wash out the local tensions and
                controversies and disputes.
              </li>
              <li>Believe in the unity of colleagues.</li>
            </ol>
            <h5 className="font-weight-bold"> (d) TEACHERS AND AUTHORITIES:</h5>
            <h className="font-weight-bold fs-5">A teacher shall:</h>
            <ol type="i">
              <li>
                Discharge their professional responsibilities according to the
                existing rules and adhere to procedures and methods consistent
                with their profession in initiating steps through their
                university for change of any such rule detrimental to the
                professional interest.
              </li>
              <li>
                Not undertake any other employment and commitment including
                private tuition and coaching classes.
              </li>
              <li>
                Co-operate in the formulation of policies of the institution by
                accepting various offices and discharging responsibilities that
                such offices may demand.
              </li>
              <li>
                Co-operate with the authorities for the betterment of the
                institutions keeping in view the interest and in conformity with
                the dignity of the profession
              </li>
              <li>Should adhere to the conditions of the contract.</li>
              <li>
                Give and expect due notice before a change of position is made.
              </li>
              <li>
                Refrain from availing themselves of leave except on unavoidable
                grounds and as far as practicable with prior intimation, keeping
                in view their particular responsibility for completion of the
                academic schedule.
              </li>
            </ol>
            <h5 className="font-weight-bold">
              (e) TEACHERS AND NONTEACHING EMPLOYEES:
            </h5>
            <p>
              The teachers should treat the non-teaching employees as colleagues
              with equality and fraternity.
            </p>
            <h5 className="font-weight-bold"> (f) TEACHERS AND GUARDIANS:</h5>
            <p>
              The teachers shall try to maintain contact with the guardians of
              their students, send a report of their performance to the
              guardians whenever necessary, and meet the guardians in meetings
              convened for mutual exchange of ideas and the benefit of the
              university.
            </p>
            <h5 className="font-weight-bold"> (g) TEACHERS AND SOCIETY:</h5>
            <h className="font-weight-bold fs-5">The teachers shall</h>
            <ol type="i">
              <li>
                Recognize that education is a public service and strive to keep
                the public informed of the educational programmes which are
                being provided.
              </li>
              <li>
                Work to improve education in the community and strengthen the
                community’s moral and intellectual life.
              </li>
              <li>
                Be aware of social and economic problems and take part in such
                activities as would be conducive to the progress of society and
                hence the country as a whole.
              </li>
              <li>
                Perform the duties of citizenship, participate in community
                activities, and shoulder responsibilities of public office.
              </li>
              <li>
                Refrain from taking part in or subscribing to or assisting in
                any way, activities that tend to promote a feeling of hatred or
                enmity among communities, relations, or linguistic groups but
                actively work for national integration.
              </li>
            </ol>
            <ol type="1">
              <li>
                Maintain the highest standards of discipline and dignified
                manner of behaviour inside as well as outside the university
                campus. They shall abide by the rules and regulations of the
                university and should act in a way that highlights the
                discipline and esteem of the institute.
              </li>
              <li>
                Work in the time schedule from Monday to Friday is followed.
                Classes are scheduled from 9.15 a.m. to 3.00 p.m. All the
                students are expected to be present in the class well - within
                time and late coming will attract a loss of attendance for the
                corresponding hour.
              </li>
              <li>
                Encourage student seminars/workshops/webinars/project
                presentations etc., all the students of the concerned class must
                be present for the entire session.
              </li>
              <li>
                Permit students in case of sickness or other valid grounds
                requires a gate pass with an application duly forwarded by the
                HOD and Dean. Application for medical leave shall be accompanied
                by valid medical fitness certificate of the treating Doctor
                while resuming classes after illness.
              </li>
              <li>
                Advise to refrain from activities such as scribbling or noting
                on student desks, doors, or other furniture which could deface
                the university and destroy the academic ambiance.
              </li>
              <li>
                Advise students to handle the chemicals, apparatus, equipment,
                fixtures and appliances carefully of the laboratories/workshops
                of the university. Careless handling/misuse of the above could
                result in personal injuries or damage to property. Follow safety
                precautions near moving machines and electrical installations.
                In the event of property damage, the responsible students will
                have to bear the cost of replacement/repair with a fine.
              </li>
              <li>
                Students are not allowed to arrange any unauthorized
                celebrations and decorations of any magnitude in the campus.
              </li>
              <li>
                Students are not allowed to distribute or display (both
                physically and electronically) material such as notices or
                banners etc. in the campus without the permission of the
                competent authority.
              </li>
              <li>
                Initiate action in case of non-compliance the code of conduct
                means for students or any observed matter/behaviour that
                deviates from the vision and mission of the code of conduct, all
                the faculty members are empowered to initiate the disciplinary
                procedure by filling in a standard form which in turn may lead
                to the constitution of an inquiry committee for further
                proceedings.
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Cocfaculty;
