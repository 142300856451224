import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, Link, useLocation } from "react-router-dom";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { MetaTags } from "react-meta-tags";
import Footer from "../common/footer";
import EnquiryNow from "../common/EnquiryNow3";
import Allinone from "../common/Allinone";
import { Container, Row, Col, Breadcrumb, Modal } from "react-bootstrap";
import Brochure from "../../Images/Brochure/ISBM_PROSPECTUS.pdf";
import Prospectus from "../../Images/Brochure/Yoga & Naturopathy.pdf";

const CourseDetailsVTwo = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [show, setShow] = useState(false);
  const { courseName } = useParams();
  const [course, setCourse] = useState(null);
  const [schoolName, setSchoolName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [courseList, setCourseList] = useState([]);
  const [careerList, setCareerList] = useState([]);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchCourseDetails = async () => {
      try {
        const response = await axios.get(
          `https://isbmuniversity.edu.in/admin-portal/courses/${courseName}`
        );
        const courseData = response.data.data[0];
        setCourse(courseData);
        setSchoolName(courseData.school_name);
      } catch (error) {
        setError("Error fetching course details");
        console.error("Error fetching course details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseDetails();
  }, [courseName]);

  useEffect(() => {
    if (schoolName) {
      const fetchCourseList = async () => {
        try {
          const response = await axios.get(
            `https://isbmuniversity.edu.in/admin-portal/coursesList/${schoolName}`
          );
          setCourseList(response.data.data);
        } catch (error) {
          console.error("Error fetching courseList:", error);
        }
      };

      fetchCourseList();
    }
  }, [schoolName]);

  useEffect(() => {
    if (schoolName) {
      const fetchCareerList = async () => {
        try {
          const response = await axios.get(
            `https://isbmuniversity.edu.in/admin-portal/career_opportunity/${schoolName}`
          );
          setCareerList(response.data.careers);
        } catch (error) {
          console.error("Error fetching careerList:", error);
        }
      };

      fetchCareerList();
    }
  }, [schoolName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // Assuming `course.specialization` contains HTML string
  // Parse it to extract the list items if it's raw HTML
  const parseSpecializations = (html) => {
    // Create a temporary element to parse HTML string
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.querySelectorAll("li");
  };

  const specializationItems = parseSpecializations(course.specialization);

  return (
    <React.Fragment>
      {course && (
        <>
          <MetaTags>
            <title>{course.metatitle}</title>
            <meta name="description" content={course.metadesc} />
            <link
              rel="canonical"
              href={`https://isbmuniversity.edu.in/course-details/${course.name}`}
            />
            <meta
              name="SearchEngines"
              content="Google, Bing, Overture, Yahoo, AOL, Infoseek, LookSmart, Excite, Hotbot, Lycos, CNET,
              DogPile, Ask Jeeves, Teoma, Snap, WebCrawler, AltaVista, GigaBlast, isoHunt, Kayak, MetaCrawler"
            />
            <meta name="Robots" content="INDEX, FOLLOW" />
            <meta
              name="Author"
              content="ISBM University, isbmuniversity.edu.in/"
            />
            <meta name="Robots" content="INDEX,ALL" />
            <meta name="YahooSeeker" content="INDEX, FOLLOW" />
            <meta name="msnbot" content="INDEX, FOLLOW" />
            <meta name="googlebot" content="Default, follow" />
            <meta name="owner" content="isbmuniversity.edu.in/" />
            <meta name="language" content="English" />
            <meta name="revisit-after" content="2 days" />
            <meta property="og:title" content={course.metatitle} />
            <meta property="og:description" content={course.metadesc} />
            <meta
              property="og:url"
              content={`https://isbmuniversity.edu.in/course-details/${course.name}`}
            />
            <meta
              property="og:image"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
            />
            <meta property="og:image:alt" content="ISBM University" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="ISBM University" />
            <meta name="twitter:title" content={course.metatitle} />
            <meta name="twitter:description" content={course.metadesc} />
            <meta
              name="twitter:image:src"
              content="https://isbmuniversity.edu.in/static/media/commb.0f9333e0.jpg"
            />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:site" content="@ISBMUniversity" />
            <meta name="twitter:creator" content="@ISBMUniversity" />
            <meta
              name="twitter:domain"
              content="https://isbmuniversity.edu.in/"
            />
            <meta property="twitter:account_id" content="758619542734053376" />
          </MetaTags>
          <Topnav />
          <Menubar />
          <div
            className={`innerbackimage position-relative ${course.banner}`}
            aria-label="Best University in Chhattisgarh"
          >
            <div class="bannerShape">
              <div class="bannerDetails">
                <h1 class="text-gradient mb-3">{course.title}</h1>
                <div
                  class="mb-3 small"
                  dangerouslySetInnerHTML={{
                    __html: course.banner_description,
                  }}
                ></div>
                <div class="row mx-0">
                  <div class="bannerIcons align-items-center me-2 mb-4">
                    <div class="d-flex">
                      <i
                        class="fa-solid fa-user-graduate me-2"
                        aria-hidden="true"
                      ></i>
                      <p class="m-0 fw-bold">{course.level}</p>
                    </div>
                  </div>
                  <div class="d-flex bannerIcons align-items-center me-2 mb-4">
                    <i
                      class="fa-solid fa-hourglass-half me-2"
                      aria-hidden="true"
                    ></i>
                    <p class="m-0 fw-bold">{course.duration}</p>
                  </div>
                  <div class="d-flex bannerIcons align-items-center mb-4">
                    <i
                      class="fa-solid fa-graduation-cap me-2"
                      aria-hidden="true"
                    ></i>
                    <p class="m-0 fw-bold">{course.eligibility}</p>
                  </div>
                </div>
                <div class="d-flex">
                  <button
                    type="button"
                    class="btn btn-apply me-2"
                    onClick={() => setModalShow(true)}
                  >
                    Apply Now
                  </button>
                  <EnquiryNow
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                  />
                  <a
                    class="btn btn-download"
                    href={course.brochure}
                    download=""
                  >
                    Download Brochure
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="content-wrapper mt-5">
            {/* <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/school-details/${course.school_name}`}>
                  {course.school_name}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>{course.title}</Breadcrumb.Item>
            </Breadcrumb> */}

            <div class="row">
              <div class="sidebar-section col-lg-3 p-0 mb-5">
                <h3 class="title">Courses</h3>
                <ul className="abcd1111 shadow card border-0">
                  {courseList.map((courseTitle) => (
                    <li
                      key={courseTitle.id}
                      className={
                        location.pathname ===
                        `/course-details/${courseTitle.name}`
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={`/course-details/${courseTitle.name}`}>
                        {courseTitle.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div class="col-lg-9">
                <div class="row">
                  <div class="col-lg-12">
                    <h2 class="linkabout123">{course.title}</h2>
                    <div
                      class="overview"
                      dangerouslySetInnerHTML={{ __html: course.description }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section class="py-5 color-section-dark text-white text-center">
            <h2>Transform Your Tomorrow</h2>
            <p class="w-50 m-auto text-center my-4">
              Unlock endless possibilities and shape your future today.
              Transform your tomorrow with the education that empowers you to
              achieve greatness.
            </p>
            <button
              type="button"
              class="btn btn-download px-5 me-2"
              onClick={() => setModalShow2(true)}
            >
              Apply Now
            </button>
            <EnquiryNow show={modalShow2} onHide={() => setModalShow2(false)} />
          </section>
          {specializationItems.length > 0 && (
            <section className="course-specializations py-5">
              <div className="container py-5">
                <div id="specializations" className="specializations py-5">
                  <div className="container">
                    <h2 className="specializations-header mt-4">
                      <b>Specializations Offered:- </b>{course.specialization_selection}
                    </h2>
                    <h4 className="specializations-header2 mt-2">
                      <b>{course.title}</b>
                    </h4>
                    <ul className="row mt-4">
                      {Array.from(specializationItems).map((item, index) => (
                        <li key={index} className="col-lg-4 col-md-4 col-sm-12">
                          {item.textContent}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
          )}

          <div id="outcomes" class="outcome py-5 color-section">
            <div class="container">
              <figure>
                <h2 class="black_title">Course Outcomes</h2>
                <figcaption class="blockquote-footer mb-4">
                  Uncover the findings from your studies
                </figcaption>
              </figure>
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: course.outcome }}
              ></div>
            </div>
          </div>
          <section class="container py-5">
            <div class="coList row mx-0 mt-30">
              <div class="col-lg-5 col-12">
                <div class="coCol1">
                  <figure>
                    <h2 class="black_title">Career Opportunities</h2>
                    <figcaption class="blockquote-footer mb-4">
                      Uncover the findings from your studies
                    </figcaption>
                  </figure>
                  <p>
                    Explore a world of career opportunities that align with your
                    passions. Unlock doors to success with the right skills and
                    guidance.
                  </p>
                </div>
              </div>
              <div class="col-lg-7 col-12">
                <h4 class="ps-5">Future-Ready Opportunities:</h4>
                <ul className="row mt-4">
                  {careerList && careerList.length > 0 ? (
                    careerList.map((careers) => (
                      <li
                        className="col-lg-6 col-md-6 col-12 p-2"
                        key={careers}
                      >
                        <div className="coListLi">
                          <i className="fa-solid fa-trophy me-2"></i>
                          {careers}
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>No career opportunities available</li>
                  )}
                </ul>
              </div>
            </div>
          </section>
          <Allinone />
          <Footer />
          <Modal
            show={show}
            onHide={handleClose}
            centered
            className="video_courses_abcdsa"
          >
            <Modal.Body className="p-0 bg-transparent">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/jgS_lbVI428"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Modal.Body>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
};

export default CourseDetailsVTwo;
