import React, { useEffect, useState } from "react";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import Topnav from "../common/Topnav";
import Menubar from "../common/Menubar";
import { Link } from "react-router-dom";
import Footer from "../common/footer";
import Internationalsidebaar from "../sidebars/InternationalSidebar"
import rushford from "../../Images/MOU/rushford-logo.webp";
import henry from "../../Images/MOU/henry-logo.webp";
import indiana from "../../Images/MOU/indiana-logo.webp";
import diuLogo from "../../Images/MOU/diu-logo.webp";

const Internationalpartnership = () => {
    return (
      <>
       
        <Topnav />
        <Menubar />
        <div className="internationalpartnershipimg innerbackimage">
        </div>

        {/* container */}
        <Container className="About_us11">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">Home</Link>
            </Breadcrumb.Item>

            <Breadcrumb.Item active>International Partnerships</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Internationalsidebaar />
            <Col lg={9}>
            <h2 className="linkabout123 ">
              International Partnerships - Making connections that span the
              globe
            </h2>
            <p >
              The primary objective of the International Tie-ups is to promote
              internationalization and cross-cultural exchange in the academic
              realm. These tie-ups are aimed at fostering global academic and
              research opportunities for students, faculty, and staff members of
              ISBM University.
            </p>
            <p>
              Through these partnerships, ISBM University aims to provide its
              students with exposure to global educational practices, diverse
              perspectives, and a broader understanding of different cultures.
              Additionally, it facilitates the exchange of knowledge, research
              collaborations, and joint academic programmes between ISBM
              University and its international partners.
              </p>
              <Col >
              <Row className="mt-5">
                <Col lg={3}>
                  <img className="w-100" src={diuLogo} />
                </Col>
                <Col lg={9}>
                  <h2 className="tieupTitle">
                    Dresden INTERNATIONAL University GmbH
                  </h2>
                  <p>
                    ISBM University has signed a MoU with Dresden International
                    University, Dresden, Germany. The MoU will pave the way for
                    students to enroll and get a comprehensive knowledge and
                    skills in market oriented programmes such as MSC and MBA in
                    Logistics Management, International Commercial and Contract
                    Management, Industrial Management in Microelectronics,
                    Non-Destructive Testing, among others with work
                    opportunities post-study tenure.
                    
                  </p>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg={3}>
                  <img className="w-100" src={rushford} alt="Rushford business School"/>
                </Col>
                <Col lg={9}>
                  <h2 className="tieupTitle">Rushford business School</h2>
                  <p>
                    ISBM University and Rushford Business School are pleased to
                    announce a new partnership to offer students a global
                    business education. This partnership will allow students to
                    earn a dual degree from both universities, one from ISBM
                    University in India and one from Rushford Business School in
                    Switzerland.
                    <Link to="/rushford-business-school"  >Know more...</Link>
                  </p>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg={3}>
                  <img className="w-100" src={henry} alt="Henry Harvin"/>
                </Col>
                <Col lg={9}>
                  <h2 className="tieupTitle">Henry Harvin</h2>
                  <p>
                    ISBM University has signed a Memorandum of Understanding
                    (MoU) with Henry Harvin®, a global Edtech Company based out
                    of US and India, to conduct Training and Placement programmes
                    for its Students in over 600+ courses available with the
                    Edtech company like Six Sigma Green Belt, Tableau, Analytics
                    using Python/R (Business, Marketing, HR, Finance),
                    Blockchain Professional Course, Data Science with Python
                    Course, etc. The objective of the MoU is to offer on-the-job
                    training and practical training to students of either
                    diploma, graduation or post graduation in any stream to
                    enhance their employability and counter the issues with
                    respect to skilled labour scarcity in the region.
                    <Link to="/henry-harvin" >Know more...</Link>
                  </p>
                </Col>
              </Row>
           
              <Row className="mt-5">
                <Col lg={3}>
                  <img className="w-100" src={indiana} alt="Indiana Tech University"/>
                </Col>
                <Col lg={9}>
                  <h2 className="tieupTitle">Indiana Tech University</h2>
                  <p>
                    Our partnership with Indiana Tech signifies a commitment to
                    providing our students with a broader perspective and access
                    to a wider range of academic opportunities. This
                    collaboration opens the door to exchange programmes, joint
                    research initiatives, and shared resources, allowing
                    students from both institutions to benefit from a richer
                    educational experience.
                  </p>
                </Col>
              </Row>
              </Col>
              </Col>
          </Row>
        </Container>
        <Footer />
      </>
    );
  }


export default Internationalpartnership;
