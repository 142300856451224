import React, { useState } from "react";
import campus from "../../Images/icons/university.webp";
import hostel from "../../Images/icons/hostel.webp";
import book from "../../Images/icons/book.webp";
import campusmid from "../../Images/icons/campus.png";
import technology from "../../Images/icons/technology.webp";
import transportation from "../../Images/icons/transportation.webp";
import game from "../../Images/icons/game.webp";
import LMS from "../../Images/commonimg/lms.webp";
import { Card, Row, Col, Accordion, Modal, Button } from "react-bootstrap";
import avd1 from "../../Images/advantages/1.jpg";
import avd2 from "../../Images/advantages/2.jpg";
import avd3 from "../../Images/advantages/3.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import w1 from "../../Images/companylogo/1.webp";
import w2 from "../../Images/companylogo/2.webp";
import w3 from "../../Images/companylogo/3.webp";
import w4 from "../../Images/companylogo/4.webp";
import w5 from "../../Images/companylogo/5.webp";
import w6 from "../../Images/companylogo/6.webp";
import w7 from "../../Images/companylogo/7.webp";
import w8 from "../../Images/companylogo/8.webp";
import w9 from "../../Images/companylogo/9.webp";
import w10 from "../../Images/companylogo/10.webp";
import w11 from "../../Images/companylogo/11.webp";
import w12 from "../../Images/companylogo/12.webp";
import w13 from "../../Images/companylogo/13.webp";
import w14 from "../../Images/companylogo/14.webp";
import w15 from "../../Images/companylogo/15.webp";
import w16 from "../../Images/companylogo/16.webp";
import w17 from "../../Images/companylogo/17.webp";
import w18 from "../../Images/companylogo/18.webp";
import w19 from "../../Images/companylogo/19.webp";
import w20 from "../../Images/companylogo/20.webp";
import w21 from "../../Images/companylogo/21.webp";
import w22 from "../../Images/companylogo/22.webp";
import w23 from "../../Images/companylogo/23.webp";
import w24 from "../../Images/companylogo/24.webp";
import w25 from "../../Images/companylogo/25.webp";
import w26 from "../../Images/companylogo/26.webp";
import w27 from "../../Images/companylogo/27.webp";
import w28 from "../../Images/companylogo/28.webp";
import w29 from "../../Images/companylogo/29.webp";
import w30 from "../../Images/companylogo/30.webp";
import w31 from "../../Images/companylogo/31.webp";
import w32 from "../../Images/companylogo/32.webp";
import w33 from "../../Images/companylogo/33.webp";
import w34 from "../../Images/companylogo/34.webp";
import w35 from "../../Images/companylogo/35.webp";
import w36 from "../../Images/companylogo/36.webp";
import w37 from "../../Images/companylogo/37.webp";
import w38 from "../../Images/companylogo/38.webp";
import w39 from "../../Images/companylogo/39.webp";
import w40 from "../../Images/companylogo/40.webp";
import w41 from "../../Images/companylogo/41.webp";
import w42 from "../../Images/companylogo/42.webp";
import w43 from "../../Images/companylogo/43.webp";
import w44 from "../../Images/companylogo/44.webp";
import w45 from "../../Images/companylogo/45.webp";
import w46 from "../../Images/companylogo/46.webp";
import w47 from "../../Images/companylogo/47.webp";
import w48 from "../../Images/companylogo/48.webp";
import w49 from "../../Images/companylogo/49.webp";
import w50 from "../../Images/companylogo/50.webp";
import EnquiryNow from "../common/EnquiryNow3";

// import HorizontalTimeline from "./horizontalTimeline";
import Reccaraousal from "./reccaraousal";
const Allinone = () => {
  const [showMore, setShowMore] = useState({
    campusLife: false,
    hostel: false,
    library: false,
    technology: false,
    transportation: false,
    sports: false,
  });

  const handleToggle = (key) => {
    setShowMore((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  };
  const [modalShow, setModalShow] = React.useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const settings1 = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    slidesToShow: 10,
    slidesToScroll: 1,
    arrows: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          arrows: false,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          cssEase: "linear",
        },
      },
    ],
  };

  const settings2 = {
    ...settings1,
    rtl: true,
  };

  return (
    <div>
      <section className="color-section py-5">
        <div className="container">
          <figure>
            <h2 className="black_title">Campus Amenities</h2>
            <figcaption className="blockquote-footer mb-4">
              Your Hub for Study, Relaxation, and Everything In Between
            </figcaption>
          </figure>
          <div className="row align-items-center py-5">
            <div className="col-sm-6 col-lg-4 mb-2-9 mb-sm-0">
              <div className="pr-md-3">
                <div className="text-center text-sm-right mb-2-9">
                  <div className="mb-2">
                    <img className="rounded-circle w-25" src={campus} />
                  </div>
                  <h4 className="sub-info">
                    Discover Campus Life: Explore, Connect, Succeed
                  </h4>
                  <p className="text_feature text-dark mb-3">
                    The campus of ISBM University is designed to provide a
                    modern
                    <span className="ellipsis">...</span>
                    <span
                      className={`moreText ${
                        showMore.campusLife ? "" : "d-none"
                      }`}
                    >
                      and comfortable learning environment for students. The
                      buildings are equipped with state-of-the-art facilities
                      such as spacious classrooms, well-equipped laboratories, a
                      modern library, sports facilities, and residential
                      facilities for students.
                    </span>
                    <button
                      className="more"
                      onClick={() => handleToggle("campusLife")}
                    >
                      {showMore.campusLife ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <div className="text-center text-sm-right">
                  <div className="mb-4">
                    <img className="rounded-circle w-25" src={hostel} />
                  </div>
                  <h4 className="sub-info">
                    Experience Comfort and Community at Our Hostel
                  </h4>
                  <p className="text_feature text-dark mb-3">
                    The facility is designed to provide a safe, comfortable and
                    <span className="ellipsis">...</span>
                    <span
                      className={`moreText ${showMore.hostel ? "" : "d-none"}`}
                    >
                      convenient living environment for students who come from
                      outside the city or country. University hostels offer a
                      range of services and amenities for students, such as
                      24-hour security, internet access, and recreational
                      activities. Hostel facilities from a university are
                      designed to provide a supportive and inclusive community
                      for students as they pursue their academic goals, while
                      also helping them develop important life skills and
                      relationships that can last a lifetime.
                    </span>
                    <button
                      className="more"
                      onClick={() => handleToggle("hostel")}
                    >
                      {showMore.hostel ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <div className="text-center text-sm-right">
                  <div className="mb-4">
                    <img className="rounded-circle w-25" src={book} />
                  </div>
                  <h4 className="sub-info">Discover the World Through Books</h4>
                  <p className="text_feature text-dark mb-3">
                    The library's physical space is designed to create a
                    peaceful and
                    <span className="ellipsis">...</span>
                    <span
                      className={`moreText ${showMore.library ? "" : "d-none"}`}
                    >
                      conducive learning environment. The library has reading
                      rooms, computer labs, and group discussion areas. The
                      reading rooms are equipped with comfortable seating and
                      ample study space for individual reading and research. The
                      computer labs provide access to computers and internet
                      connectivity for research purposes.
                    </span>
                    <button
                      className="more"
                      onClick={() => handleToggle("library")}
                    >
                      {showMore.library ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 d-none d-lg-block">
              <div className="why-choose-center-image">
                <img className="rounded-circle w-100" src={campusmid} />
              </div>
            </div>
            <div className="col-sm-6 col-lg-4">
              <div className="pl-md-3">
                <div className="text-center text-sm-left mb-2-9">
                  <div className="mb-4">
                    <img className="rounded-circle w-25" src={technology} />
                  </div>
                  <h4 className="sub-info">Get Connected to the Technology</h4>
                  <p className="text_feature text-dark mb-3">
                    The Computer Labs at ISBM University are modern and well-
                    <span className="ellipsis">...</span>
                    <span
                      className={`moreText ${
                        showMore.technology ? "" : "d-none"
                      }`}
                    >
                      equipped facility that provide students with access to the
                      latest hardware and software technologies. The labs are
                      designed to support a wide range of academic programs and
                      research initiatives, including computer science,
                      engineering, business, and other related disciplines. The
                      computer labs are staffed by experienced technicians who
                      provide support to students, faculty, and staff. They are
                      available to assist with software installation, hardware
                      troubleshooting, and other technical issues.
                    </span>
                    <button
                      className="more"
                      onClick={() => handleToggle("technology")}
                    >
                      {showMore.technology ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <div className="text-center text-sm-left">
                  <div className="mb-4">
                    <img className="rounded-circle w-25" src={transportation} />
                  </div>
                  <h4 className="sub-info">
                    Connecting People Through Efficient Transportation
                  </h4>
                  <p className="text_feature text-dark mb-3">
                    We prioritize efficient transportation to ensure that
                    students, faculty, and staff 
                   
                    <span className="ellipsis">...</span>
                    <span
                      className={`moreText ${
                        showMore.transportation ? "" : "d-none"
                      }`}
                    >
                      can easily navigate the campus and beyond. Our well-planned transport services connect key areas
                      within the campus, making it convenient for everyone to
                      access academic buildings, libraries, and recreational
                      facilities with ease.
                    </span>
                    <button
                      className="more"
                      onClick={() => handleToggle("transportation")}
                    >
                      {showMore.transportation ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
                <div className="text-center text-sm-left">
                  <div className="mb-4">
                    <img className="rounded-circle w-25" src={game} />
                  </div>
                  <h4 className="sub-info">Get Your Game On</h4>
                  <p className="text_feature text-dark mb-3">
                    A sports facility at ISBM University is a dedicated space
                    where
                    <span className="ellipsis">...</span>
                    <span
                      className={`moreText ${showMore.sports ? "" : "d-none"}`}
                    >
                      students, faculty, and staff can engage in various
                      physical activities and sports. The facility is equipped
                      with modern amenities and equipment to cater to the needs
                      of individuals with diverse interests in sports. The
                      facility is designed to accommodate both indoor and
                      outdoor sports activities, making it convenient for users
                      to engage in their preferred sports irrespective of the
                      weather conditions.
                    </span>
                    <button
                      className="more"
                      onClick={() => handleToggle("sports")}
                    >
                      {showMore.sports ? "Read Less" : "Read More"}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-blue">
        <div className="container">
          <div className="p-5 row ">
            <div className="col-lg-6">
              <h2 className="text-white mb-4 fw-bold">
                Transforming Education Through the Power of Technology
              </h2>
              <div>
                Our Learning Platform is a dynamic space where learners can
                access a variety of resources and tools to enhance their
                knowledge and skills. It provides a personalized and interactive
                learning environment that encourages exploration, collaboration,
                and critical thinking. With our Learning Platform, learners can
                take control of their learning experience and engage in
                activities that suit their learning style and pace.
              </div>
            </div>
            <div className="col-lg-6">
              <img src={LMS} alt="LMS"></img>
            </div>
          </div>
        </div>
      </section>
      <section className="faq_area py-5">
        <div className="container">
          <Row className="align-items-center">
            <Col
              lg={6}
              md={6}
              className="order-2 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0"
            >
              <Row className="align-items-center">
                <Col lg={6} md={6} xs={6}>
                  <Row>
                    <Col lg={12} md={12} className="mt-4 pt-2">
                      <Card>
                        <img
                          className="work-desk rounded border-0 shadow-lg overflow-hidde"
                          src={avd1}
                        />
                        <div className="img-overlay bg-dark"></div>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col lg={6} md={6} xs={6}>
                  <Row>
                    <Col lg={12} md={12}>
                      <Card>
                        <img
                          className="work-desk rounded border-0 shadow-lg overflow-hidde"
                          src={avd2}
                        />
                        <div className="img-overlay bg-dark"></div>
                      </Card>
                    </Col>

                    <Col lg={12} md={12} className="mt-4 pt-2">
                      <Card>
                        <img
                          className="work-desk rounded border-0 shadow-lg overflow-hidde"
                          src={avd3}
                        />
                        <div className="img-overlay bg-dark"></div>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col lg={6} md={6} xs={12} className="order-1 order-md-2">
              <div className="ml-lg-5">
                <figure>
                  <h2 className="black_title">The Differential Edge</h2>
                  <figcaption className="blockquote-footer mb-4">
                    Your Hub for Study, Relaxation, and Everything In Between
                  </figcaption>
                </figure>

                <Row className="justify-content-center">
                  <Accordion
                    defaultActiveKey="0"
                    id="accordionExample"
                    className="faq-accordion"
                  >
                    <Accordion.Item eventKey="0" className="shadow mb-4">
                      <Accordion.Header>Innovative Pedagogy</Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <p className="text-decoration-underline fw-bold">
                            Project-based learning
                          </p>
                          <p>
                            The university uses project-based learning to
                            encourage students to work collaboratively and apply
                            their learning to real-world problems.
                          </p>
                          <p className="text-decoration-underline fw-bold">
                            Blended Learning
                          </p>
                          <p>
                            It combines traditional classroom teaching with
                            online learning to create a personalized learning
                            experience.
                          </p>
                          <p className="text-decoration-underline fw-bold">
                            Collaborative Learning
                          </p>
                          <p>
                            It involves students working in groups to solve
                            problems and share knowledge.
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="1" className="shadow mb-4">
                      <Accordion.Header>
                        Industry-focused Curriculum
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <p>
                            Industry-focused curriculum refers to an educational
                            program that is specifically designed to address the
                            needs and demands of the industry.
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="2" className="shadow mb-4">
                      <Accordion.Header>
                        Comprehensive Academic Assistance
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <p>
                            Comprehensive Academic Assistance refers to the
                            variety of programs, services, and resources that
                            educational institutions provide to support
                            students' academic success.
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="3" className="shadow mb-4">
                      <Accordion.Header>
                        Employment Opportunities
                      </Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <p>
                            ISBM University also offers placement services to
                            its students. The university has a dedicated
                            placement cell that works with various companies and
                            organizations to provide job opportunities to its
                            students.
                          </p>
                          <p>
                            The placement cell conducts various activities and
                            programs to help students prepare for their careers,
                            such as resume writing workshops, mock interviews,
                            and career counseling sessions.
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="4" className="shadow">
                      <Accordion.Header>Experienced Faculty</Accordion.Header>
                      <Accordion.Body>
                        <div>
                          <p className="text-decoration-underline fw-bold">
                            In-depth knowledge
                          </p>
                          <p>
                            Experienced faculty members possess a deep
                            understanding of their subject matter and are able
                            to provide students with a comprehensive
                            understanding of the topic.
                          </p>
                          <p>
                            Expert guidance: Experienced faculty members can
                            provide expert guidance and mentoring to students,
                            helping them to develop their skills and talents and
                            achieve their academic and career goals.
                          </p>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section>
        <Row className="recRow mx-0">
          <Col lg={7} md={6}>
            <div className="recDetails">
              <div className="recInfo p-4">
                <h2 className="text-white">
                  Accredited for Success, Connected for Growth
                </h2>
                <p>
                  Our accreditation ensures that you meet and exceed industry
                  standards, while our robust membership network fosters
                  invaluable connections and opportunities for growth.
                </p>
                <p>
                  Enroll now to earn your degree from a highly recognized
                  university.
                </p>
                <Button
                  className="btn-download"
                  onClick={() => setModalShow(true)}
                >
                  Apply Now
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={5} md={6} className="recImages">
            <div className="recognition p-4">
              <Reccaraousal />
            </div>
          </Col>
        </Row>

        <EnquiryNow show={modalShow} onHide={() => setModalShow(false)} />
      </section>
      <section className="blueRow2">
        <div className="container">
          <h2 className="white_title text-center">
            Our Learners Leading the Way in Diverse Industries
          </h2>
          <p className="text-center">
            Equipped with unique skills, our graduates are making their mark
            across various fields.
          </p>
          <div className="learners2 mt-5">
            <Slider {...settings1}>
              <div className="item">
                <img className="itemImg " src={w1} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w2} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w3} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w4} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w5} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w6} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w7} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w8} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w9} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w10} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w11} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w12} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w13} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w14} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w15} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w16} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w17} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w18} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w19} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w20} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w21} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w22} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w23} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w24} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w25} alt="Image"></img>
              </div>
            </Slider>
          </div>
          <div className="learners3 mt-5">
            <Slider {...settings2}>
              <div className="item">
                <img className="itemImg" src={w26} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w27} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w28} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w29} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w30} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w31} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w32} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w33} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w34} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w35} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w36} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w37} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w38} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w39} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w40} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w41} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w42} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w43} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w44} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w45} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w46} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w47} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w48} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w49} alt="Image"></img>
              </div>
              <div className="item">
                <img className="itemImg" src={w50} alt="Image"></img>
              </div>
            </Slider>
          </div>
        </div>
      </section>
      {/* <Timeline /> */}

      <section className="UpgradSupport page-section py-5">
        <div className="container">
          <h5>For queries, feedback &amp; assistance</h5>
          <h1>ISBMU Learner Support</h1>
          <h4>(We are available 24*7)</h4>
          <div className="row">
            <div className="col-lg-6 p-2">
              <h3>Call us at</h3>
              <button>
                <a href="tel:+91 9373199999" style={{ color: "white" }}>
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  +91 9373199999
                </a>
              </button>
            </div>
            <div className="col-lg-6 p-2">
              <h3>Email us at</h3>
              <button>
                <a
                  href="mailto:info@isbmuniversity.ac.in"
                  style={{ color: "white" }}
                >
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  info@isbmuniversity.ac.in
                </a>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Allinone;
